var Helpers = (function () {
    function Helpers() {
    }
    Helpers.debounce = function (fn, delay) {
        var timer = null;
        delay = delay || 300;
        return function () {
            var context = this;
            var args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args);
            }, delay);
        };
    };
    Helpers.scopedLoop = function (list, fn, context) {
        for (var i = 0, len = list.length; i < len; i++) {
            (function (i) {
                fn(list[i], i, context);
            }).call(this, i);
        }
    };
    Helpers.createCustomEventForDispatch = function (eventName, data) {
        if (data === void 0) { data = {}; }
        var event;
        if (window.CustomEvent && typeof window.CustomEvent === 'function') {
            event = new CustomEvent(eventName, { detail: data });
        }
        else {
            event = document.createEvent('CustomEvent');
            event.initCustomEvent(eventName, true, true, data);
        }
        return event;
    };
    Helpers.isLegacyIE = function () {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        var trident = ua.indexOf('Trident/');
        var edge = ua.indexOf('Edge');
        return (msie > 0 || trident > 0 || edge > 0);
    };
    Helpers.serialize = function (form, makeArray) {
        if (makeArray === void 0) { makeArray = false; }
        var serialized = [];
        var elements = form.querySelectorAll('input,select,textarea');
        for (var i = 0; i < elements.length; i++) {
            var field = elements[i];
            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') {
                continue;
            }
            if (field.type === 'select-multiple') {
                for (var j = 0; j < field.options.length; j++) {
                    if (!field.options[j].selected)
                        continue;
                    if (makeArray) {
                        serialized.push({
                            name: encodeURIComponent(field.name),
                            value: encodeURIComponent(field.options[j].value)
                        });
                    }
                    else {
                        serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value));
                    }
                }
            }
            else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                if (makeArray) {
                    serialized.push({
                        name: encodeURIComponent(field.name),
                        value: encodeURIComponent(field.value)
                    });
                }
                else {
                    serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
                }
            }
        }
        if (makeArray) {
            return serialized;
        }
        return serialized.join('&');
    };
    Helpers.getKeypressed = function (e) {
        var key = e.key;
        if (key !== undefined) {
            return key;
        }
        var keyCode = e.keyCode;
        if (keyCode !== undefined) {
            return keyCode;
        }
    };
    Helpers.prevAll = function (element, limit) {
        var result = [];
        var count = 0;
        while (element = element.previousElementSibling) {
            if (count > limit)
                break;
            result.push(element);
            count++;
        }
        return result;
    };
    Helpers.nextAll = function (element, limit) {
        var result = [];
        var count = 0;
        while (element = element.nextElementSibling) {
            if (count > limit)
                break;
            result.push(element);
            count++;
        }
        return result;
    };
    return Helpers;
}());
new Helpers();
window.Helpers = Helpers;
